import { WindowLocation } from "@reach/router";
import { Blok } from "../interfaces/Blok.interface";
import { StoryblokEntry } from "../templates/storyblok-entry.template";
import { isEditMode } from "../utils/isEditMode";
import renderBloks from "../utils/renderBloks";
import useStoryblok from "./useStoryblok.hook";

const getContent = (
  storyblokEntry: StoryblokEntry,
  location: WindowLocation
): Blok[] => {
  if (isEditMode()) {
    const {
      content: { body },
    } = useStoryblok(storyblokEntry, location);

    return body;
  }

  const { body } = JSON.parse(storyblokEntry.content);

  return body;
};

const useDynamicComponent = (
  storyblokEntry,
  location: WindowLocation
): JSX.Element[] => {
  const content = getContent(storyblokEntry, location);
  return renderBloks(content);
};

export default useDynamicComponent;
