import { graphql } from "gatsby";
import React from "react";
import Layout from "../components/Layout/Layout.component";
import useDynamicComponent from "../hooks/useDynamicComponent.hook";
import { PageProps } from "gatsby";
import getMetaData from "../utils/getMetaData";

export interface StoryblokEntry {
  content: string;
  full_slug: string;
  id: string;
  name: string;
}

interface StoryblokEntryData {
  storyblokEntry: StoryblokEntry;
}

const StoryblokEntryTemplate: React.FC<PageProps<StoryblokEntryData>> = ({
  data,
  location,
}) => {
  const blocks = useDynamicComponent(data.storyblokEntry, location);
  const seo = getMetaData(data?.storyblokEntry.content);

  return (
    <Layout metaData={seo} location={location}>
      {blocks}
    </Layout>
  );
};

export const query = graphql`
  query ($id: String!) {
    storyblokEntry(id: { eq: $id }) {
      id
      name
      full_slug
      content
    }
  }
`;

export default StoryblokEntryTemplate;
